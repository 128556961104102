export const joinStringsWithFallback = (
	strings: (string | null | undefined)[],
	fallbackText: string,
	delimiter = "/",
) => strings.filter(Boolean).join(` ${delimiter} `) || fallbackText;

export function getNameInitials(name: string): string {
	if (!name) return "";

	return name
		.trim()
		.split(" ")
		.filter(Boolean)
		.map((namePart) => namePart[0]!.toUpperCase())
		.join("");
}

/**
 * example input: '<p style="min-height:21px">Hello world.</p><p>Helloworld</p>'
 * expected output: 'Hello world. Helloworld'
 */
export const convertHtmlToText = (htmlString: string): string => {
	const regex = />[\w\s.?!-]+</gi;
	const result = htmlString.match(regex);

	return !result
		? ""
		: result
				.map((match) => match.slice(1).replace("<", " "))
				.join("")
				.trimEnd();
};

export const truncate = (text: string, characters: number): string =>
	text.length <= characters ? text : `${text.slice(0, characters)}...`;
